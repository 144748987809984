import React, {useEffect, useState} from 'react';
import { useForm as hookUseForm } from 'react-hook-form';
import { AcceptDialog, ErrorMessage, SubmitFormButton, Toast, calculatePastDays, getPlanStatus } from '../../controls/UIControls';
import * as CONSTANTS from '../../../assets/constants';
import { useFetch } from '../../../hooks/useFetch';
import { useAuthToken } from '../../../hooks/useAuthToken';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Select from "react-select";
import {
    LABEL_DOCUMENT_PLACEHOLDER,
    MESSAGE_DOCUMENT_ALPHANUMERIC_INVALID,
    MESSAGE_DOCUMENT_NUMERIC_INVALID,
    PATTERN_ALPHANUMERIC,
    PATTERN_NUMERIC,
} from "../../../assets/constants";
import moment from 'moment';
import Modal from '@mui/material/Modal';
/**
 * Creado por Equilibrio Agency 2022<br>
 * Página contenido de sección usuarios
 * @interface UserCreateEditSection
 */
export const UserCreateEditSection = () => {

    const { register, handleSubmit, watch, setValue, getValues, formState: { errors }} = hookUseForm({reValidateMode: "onChange"});

    const [users, setUsers] = useState({});
    const [plans, setPlans] = useState([]);
    const [editSensitiveData, setEditSensitiveData] = useState(0);
    const [history, setHistory] = useState([]);
    const [historyCourses, setHistoryCourses] = useState([]);
    const [logSession, setLogSession] = useState([]);
    const [totalSession, setTotalSession] = useState([]);
    const [courses, setCourses] = useState([]);    
    const [companies, setCompanies] = useState([]);    
    const [segments, setSegments] = useState([]);    
    const [showModalUpdated, setShowModalUpdated] = useState(false);
    const [showModalChangeB2B, setShowModalChangeB2B] = useState(false);
    const [showModalChangeSegment, setShowModalChangeSegment] = useState(false);
    const [selectedSegment, setSelectedSegment] = useState(0);
    const [selectedPlan, setSelectedPlan] = useState('');
    const [selectedOption, setSelectedOption] = useState('');
    const [approvalCode, setApprovalCode] = useState('');
    const { authToken, getSessionUser }= useAuthToken(true);
    const [fetchData, handleFetch, handleFetchPDF] = useFetch();
    const [toastProperties, setToastProperties] = useState({show: false});
    const params = useParams();
    const [waiting, setWaiting] = useState(false);
    const [showToast, setShowToast] = useState({show:false, message:''});
    const sessionUser = getSessionUser();
    const isAdmin = sessionUser.type === 0 ||  sessionUser.type === 1;
    // Handle cerrar toast
    const afterCloseToast = () => {
        setToastProperties({ show: false });
    }
    const company = watch('companyId');
    const type = watch('type');
    const segmentId = watch('segmentId');

    const refreshLogsLogins = () => {
        // consulta de endpoint para historico de inicios de sesión realizados por el usuario, se pasa por parámetro id del usuario
        const responseph = handleFetch({
            url:`bo/viewlogs?userId=${params.id}&action=login&$limit=5`,
            method: 'GET',
            headers: {Authorization: `Bearer ${authToken}`}
        });
        responseph
            .then((r) => {
                    setLogSession(r.data)
                    setTotalSession(r.total)
                },
                error => {
                    console.log(error.errors);
                    if(error.errors) {
                    }
                })
    }
    const refreshPlans = () => {
        // consulta de endpoint para historico de planes adquiridos por el usuario, se pasa por parámetro id del usuario
        const responseph = handleFetch({
            url:`bo/userplans?userId=${params.id}&$limit=12`,
            method: 'GET',
            headers: {Authorization: `Bearer ${authToken}`}
        });
        responseph
            .then((r) => {
                    setHistory(r.data)
                },
                error => {
                    console.log(error.errors);
                    if(error.errors) {

                    }

                })
    }
    const refreshPurchaseCourses = () => {
        // consulta de endpoint para historico de planes adquiridos por el usuario, se pasa por parámetro id del usuario
        const responseph = handleFetch({
            url:`bo/coursespurchased?userId=${params.id}&$limit=20`,
            method: 'GET',
            headers: {Authorization: `Bearer ${authToken}`}
        });
        responseph
            .then((r) => {
                    setHistoryCourses(r.data)
                },
                error => {
                    console.log(error.errors);
                    if(error.errors) {

                    }

                })
    }
    const refreshUser = () => {
        const responsed = handleFetch({
            url: `bo/webusers/${params.id}`,
            method: 'GET',
            headers: {Authorization: `Bearer ${authToken}`}
        });
        responsed
            .then(async (r) => {
                    setUsers(r)
                    setValue("name", r.name);
                    setValue("lastname", r.lastname);
                    setValue("email", r.email);
                    setValue("phone", r.phone);
                    setValue("address", r.address);
                    setValue("document", r.document);
                    setValue("documentType", r.documentType);
                    setValue("companyId", r.companyId);
                    setValue("active", r.active);
                    setValue("type", r.type);
                    setValue("segmentId", r?.segmentId);
                    await handleFetch({
                        url: `bo/segment?$limit=500&companyId[$eq]=${r.companyId}`,
                        method: 'GET',
                        headers: { Authorization: `Bearer ${authToken}` }
                    }).then((r) => {
                            if(r.data.length===0){
                                setSegments([
                                    { "type": 1, "name": "Público General"},
                                    { "type": 2, "name": "Estudiante Eanista"},
                                    { "type": 3, "name": "Docente de Planta" },
                                    { "type": 4, "name": "Docente de Cátedra"},
                                    { "type": 5, "name": "Colaborador" },
                                    { "type": 6, "name": "Egresado" } ])
                            }else{
                                setSegments(r.data)
                            }
                        },
                            error => {
                                console.log(error.errors);
                            })
                    await handleFetch({
                        url:`bo/plans?$limit=500&companyId[$eq]=${r.companyId}`,
                        method: 'GET',
            
                    }).then((rp) => {
                                setPlans(rp)
                            },
                            error => {
                                console.log(error.errors);
                                if(error.errors) {
            
                                }
            
                            })
                },
                error => {
                    navigate('/admin/usuarios');
                    console.log(error.errors);
                })
    }
    // consulta de endpoint de planes para obtener información de plan individual, se recibe parametro llamado planID
    useEffect(() => {
        refreshUser();
        refreshPlans();        
        refreshPurchaseCourses();
        refreshLogsLogins();        
        //consulta de endpoint de planes disponibles
        const responsecomp = handleFetch({
            url: `bo/companiesb2b`,
            method: 'GET',
            headers: { Authorization: `Bearer ${authToken}` }
        });
        responsecomp
            .then((r) => {
                setCompanies(r.data)
            },
                error => {
                    console.log(error.errors);
                })
        // consulta de endpoint para historico de cursos inscritos por el usuario, se pasa por parámetro id del usuario
        const responsech = handleFetch({
            url:`bo/reportscourses?userId=${params.id}&$limit=500`,
            method: 'GET',
            headers: {Authorization: `Bearer ${authToken}`}
        });
        responsech
            .then((r) => {
                    setCourses(r.data)
                },
                error => {
                    console.log(error.errors);
                    if(error.errors) {
                    }
                })
    }, [])
    const navigate = useNavigate();
    const [dialogProperties, setDialogProperties] = useState({open:false});
    const numericPattern = PATTERN_NUMERIC;
    const alphanumericPattern = PATTERN_ALPHANUMERIC;
    const numericMessage = MESSAGE_DOCUMENT_NUMERIC_INVALID;
    const alphanumericMessage = MESSAGE_DOCUMENT_ALPHANUMERIC_INVALID;
    const [docPatternMessage, setDocPatternMessage] = useState({pattern: numericPattern, message: numericMessage} );
    // Al cerrar dialigo se reenvia a ruta principal de administración de usuarios
    const afterCloseOKDialog = () => {
        navigate('/admin/usuarios');
    }
    // Cerrar dialigo en caso de error
    const afterCloseErrorDialog = () => {
        setDialogProperties({open:false});
    }
    // Función submit para editar usuario
    const onSubmit = (data) => {
        let auxdata = {
            email: data.email,
            name: data.name,
            lastname: data.lastname,
            phone: data.phone,
            address: data.address,
            document: data.document,
            documentType: data.documentType,
            type: data.type,
            idDocument: `${data.documentType}:${data.document}`
        }
        if (data.password) {
            auxdata.password = data.password;
        }
        handleFetch({
            url: `bo/webusers/${params.id}`,
            method: 'PATCH',
            data: auxdata,
            headers: {Authorization: `Bearer ${authToken}`},
        })
            .then(()=>{
                setDialogProperties({open:true,
                    message:'¡Usuario editado exitosamente!',
                    style:'ok',
                    button_text:'Volver a la lista de usuarios',
                    afterClose: afterCloseOKDialog});
            })
            .catch(() => {
                setDialogProperties({open:true,
                    message:'Error al editar el usuario.',
                    afterClose: afterCloseErrorDialog,
                    style:'error'});
            });
    }
    // Handle para selector de tipo de documento
    const handleSelectChange = (selectedOption) => {
        setDocPatternMessage(selectedOption.target.value === 'PA'?
            {pattern: alphanumericPattern, message: alphanumericMessage}:
            {pattern: numericPattern, message: numericMessage});
    }
    // Handle para mostrar modal con aviso de edición de datos sensibles
    const handleEditSensitiveData = () => {
        setDialogProperties({open:true,
            message:'¡Va a editar información sensible!',
            style:'warning',
            button_text:'Está seguro de continuar?',
            secondary_button: true,
            cancelClos: handleCancelEditSensitiveData,
            afterClose: handleEditSensitiveDataTrue});
    }
    // Función mostrar modal para asignar plan a usuario
    const handleOpenModal = async () => {
        setShowModalUpdated(true);
    }
    // Función ocultar modal para asignar plan a usuario
    const handleCloseModal = () => {
        setShowModalUpdated(false);
    }
    // Función mostrar modal para cambiar usuario de B2B
    const handleOpenModalChangeB2B = async () => {
        setShowModalChangeB2B(true);
    }
    // Función ocultar modal para cambiar usuario de B2B
    const handleCloseModalChangeB2B = () => {
        setShowModalChangeB2B(false);
    }
    // Función mostrar modal para cambiar usuario de Segmentación
    const handleOpenModalChangeSegment = async () => {
        setShowModalChangeSegment(true);
    }
    // Función ocultar modal para cambiar usuario de Segmentación
    const handleCloseModalChangeSegment = () => {
        setShowModalChangeSegment(false);
    }
    // Función handle para selección de selector
    const handleSelectSegmentChange = (e) => {
        setSelectedSegment(e.target.value);
    };
    // Función handle para selección de selector
    const handleSelectPlanChange = (e) => {
        setSelectedPlan(e);
    };
    // Función handle para selección de selector
    const handleSelectB2BChange = (e) => {
        setSelectedOption(e.target.value);
    };
    // Función handle para descripción del approvalCode
    const handleApprovalCodeChange = (event) => {
        const approvalCode = event.target.value;
        setApprovalCode(approvalCode);
    };
    // Handle para cancelar modal con aviso de edición de datos sensibles
    const handleCancelEditSensitiveData = () => {
        setDialogProperties({open:false});
        setEditSensitiveData(0);
    }
    // Handle para aprobar modal con aviso de edición de datos sensibles
    const handleEditSensitiveDataTrue = () => {
        setDialogProperties({open:false});
        setEditSensitiveData(1);
    }
    // Handle para asignar plan eanista a usuarios que cumplan con plan
    const handleChangeB2B =  (event) => {
        event.preventDefault();
        handleFetch({
            url: `bo/webusers/${params.id}`,
            method: 'PATCH',
            data: { companyId: selectedOption },
            headers: {Authorization: `Bearer ${authToken}`},
        })
            .then(()=>{
                setToastProperties({
                    show: true,
                    type: 'success',
                    title: 'Operación exitosa',
                    message: 'Usuario se ha cambiado exitosamente de B2B.',
                    position: { horizontal: 'left' },
                });
                refreshUser();
                setShowModalChangeB2B(false);
            })
            .catch(() => {
                setToastProperties({
                    show: true,
                    type: 'error',
                    message: 'Error al cambiar de B2B al usuario. Intenta nuevamente.',
                    position: { horizontal: 'left' },
                });
            });
        setShowModalChangeB2B(false);
    }
    // Handle para cambiar segmentación
    const handleChangeSegment =  (event) => {
        event.preventDefault();
        handleFetch({
            url: `bo/webusers/${params.id}`,
            method: 'PATCH',
            data: { segmentId: selectedSegment },
            headers: {Authorization: `Bearer ${authToken}`},
        })
            .then(()=>{
                setToastProperties({
                    show: true,
                    type: 'success',
                    title: 'Operación exitosa',
                    message: 'Usuario se ha cambiado exitosamente de Segmentación.',
                    position: { horizontal: 'left' },
                });
                refreshUser();
                setShowModalChangeSegment(false);
            })
            .catch(() => {
                setToastProperties({
                    show: true,
                    type: 'error',
                    message: 'Error al cambiar de Segmentación al usuario. Intenta nuevamente.',
                    position: { horizontal: 'left' },
                });
            });
        setShowModalChangeSegment(false);
    }
    // Handle para cambiar estado a usuarios
    const handleChangeState =  (event) => {
        event.preventDefault();
        handleFetch({
            url: `bo/webusers/${params.id}`,
            method: 'PATCH',
            data: { active: getValues('active')===1?0:1 },
            headers: {Authorization: `Bearer ${authToken}`},
        })
            .then(()=>{
                setToastProperties({
                    show: true,
                    type: 'success',
                    title: 'Operación exitosa',
                    message: 'Se ha cambiado el estado del usuario exitosamente.',
                    position: { horizontal: 'left' },
                });
                refreshUser();
            })
            .catch(() => {
                setToastProperties({
                    show: true,
                    type: 'error',
                    message: 'Error al cambiar el estado del usuario. Intenta nuevamente.',
                    position: { horizontal: 'left' },
                });
            });
    }
    // Handle para asignar plan eanista a usuarios que cumplan con plan
    const handleAssignPlan = (event) => {
        event.preventDefault();
        handleFetch({
            url: 'users/plans',
            method: 'POST',
            data: {
                    userId: params.id,
                    planId: selectedPlan?.value,
                    approvalCode: approvalCode!=='' ? approvalCode : "PLAN ASIGNADO VIA BACKOFFICE"
                    },
        })
            .then(() => {
                setToastProperties({
                    show: true,
                    type: 'success',
                    title: 'Operación exitosa',
                    message: 'Se ha añadido el plan exitosamente al usuario.',
                    position: { horizontal: 'left' },
                });
                refreshUser();                
                refreshPlans();
                refreshPurchaseCourses();
                setApprovalCode('');
                setShowModalUpdated(false);
            })
            .catch(() => {
                setToastProperties({
                    show: true,
                    type: 'error',
                    message: 'Error al asignar el plan al usuario. Intenta nuevamente.',
                    position: { horizontal: 'left' },
                });
            });
            setShowModalUpdated(false);
    }
    // Handle para calcular fecha del dia anterior para cancelar plan
    const handleYesterday = () => {
        let now = new Date();
        let DAY_IN_MILISECONDS = 24 * 60 * 60 * 1000;
        let ayer = new Date(now.getTime() - DAY_IN_MILISECONDS);
        return moment(ayer).format('YYYY-MM-DD HH:mm:ss');
    }
    // Handle para eliminar plan gratuito a un usuario (Solo es posible eliminar planes gratuitos)
    const handleDeleteFreePlan = (id, event) => {
        event.preventDefault();
        handleFetch({
            url: `bo/userplans/${id}`,
            method: 'DELETE',            
            headers: {Authorization: `Bearer ${authToken}`} 
        })
            .then(() => {
            })
            .catch(() => {
            });
            
            setToastProperties({
                show: true,
                type: 'success',
                title: 'Operación exitosa',
                message: 'Se ha cancelado el plan del usuario Exitosamente.',
                position: { horizontal: 'left' },
            });
            refreshPlans();
    }
    // Función para cerrar toast
    const afterCloseErrorToast = () => {
        setToastProperties({show:false});
    }
    // Header para historial de pagos
    const headers = [
        {
            "name":"FECHA PAGO",
            "width": "10%"
        },
        {
            "name":"CÓDIGO OPERACIÓN",
            "width": "20%"
        },
        {
            "name": "INICIO PLAN",
            "width":"10%"
        },
        {
            "name": "FIN PLAN",
            "width":"10%"
        },
        {
            "name":"PLAN ADQUIRIDO",
            "width": "20%"
        },
        {
            "name":"MONTO",
            "width": "10%"
        },
        {
            "name":"ESTADO",
            "width": "10%"
        },
        {
            "name":"",
            "width": "10%"
        }
    ];
    // Header para historial de cursos
    const headercourses = [
        {
            "name":"FECHA INICIO",
            "width": "20%"
        },
        {
            "name":"CURSOS",
            "width": "40%"
        },
        {
            "name": "PROGRESO",
            "width":"20%"
        },
        {
            "name": "CERTIFICADO",
            "width":"20%"
        }
    ];
    // Header para cursos adquiridos
    const headerpurchasecourses = [
        {
            "name":"FECHA PAGO",
            "width": "15%"
        },
        {
            "name":"CÓDIGO OPERACIÓN",
            "width": "20%"
        },
        {
            "name": "CURSO ADQUIRIDO",
            "width": "50%"
        },
        {
            "name":"MONTO",
            "width": "15%"
        }
    ];
    const EAN_LINKEDIN_ID = 58745;
    // Función para generar certificado
    const generateCertificate = async (course, index, event) => {
        event.preventDefault();
        setWaiting(true)        
        // Handle para llamar endpoint de certificados
        try {
            const blob = await handleFetchPDF({
                url: 'bo/generate_certifications/download',
                method: 'POST',
                data: {
                    "certCode": course['attr.certificationTemplateId'],
                    "courseName": course['course.title'],
                    "userId": course['webuser.id'],
                    "studyId": course?.studyId,
                    "certDate": getSpanishDate(),
                    "receiver": {
                        "fullname": `${course['webuser.name']} ${course['webuser.lastname']}`,
                        "document": course['webuser.document'],
                        "email": course['webuser.email']
                    }},
                responseType: 'blob'
            });
            setWaiting(false)
            setToastProperties({
                show: true,
                type: 'success',
                message: `<span>El certificado para el curso <strong>`+course['course.title']+`</strong> se generó exitosamente. Ya puede ser descargado.</span>`,
                position: { horizontal: 'left' },
            });
    
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'certificado.pdf';
            document.body.appendChild(a);
            a.click();
            a.remove();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            setWaiting(false)
            setShowToast({ show: true, type: 'error', 
            message: `<span>Ocurrió un error generando certificado para el curso <strong>`+course['course.title']+`</strong>. Intenta nuevamente.</span>` });
            console.error('There was an error downloading the PDF:', error);
        }
    }
    // Función para traducir mes a español
    const getSpanishDate = () => {
        const spanishMonths = ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 
                                'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'];
        const now = new Date();
        return now.getDate()+ " de " + spanishMonths[now.getMonth()] + " de " + now.getFullYear();
    }
    // Función para generar certificado
    const generateBadge = (course) => {
        const key = course.contentProviderId + '_' + course.courseId;
        setWaiting(true)
        // Handle para llamar endpoint de certificados
        handleFetch({
            url: 'certifications/badges',
            method: 'POST',
            headers: { Authorization: `Bearer ${authToken}` },
            data: {
                badgeCode: course['attr.certificationTemplateId'],
                courseName: course['course.title'],
                certDate: getSpanishDate(),
                receiver: {
                    name: course['webuser.name'],
                    lastname: course['webuser.lastname'],
                    documentType: course['webuser.documentType'],
                    document: course['webuser.document'],
                    email: course['webuser.email']
                }
            }
        }).then(cert => {
            return new Promise((resolve, reject) => {
                handleFetch({
                    url: `certifications/badges/${cert.badgeCode}`,
                    method: 'GET',
                    headers: { Authorization: `Bearer ${authToken}` },
                })
                    .then(certData => resolve({ certId: cert.badgeCode, certUrls: certData.data }))
                    .catch(error => reject(error));
            })
        }).then(certData => {
            return handleFetch({
                url: `certifications/user_badges`,
                method: 'POST',
                headers: { Authorization: `Bearer ${authToken}` },
                data: {
                    userStudyId: course['user_study.id'],
                    certificationId: certData.certId,
                    certificationUrl: certData.certUrls.urlImageBadge,
                    certificationViewUrl: certData.certUrls.urlPublicViewBadge,
                    certificationShareUrl: certData.certUrls.urlShareSocialNetwork,
                    certificationShareLinkedInUrl: `${certData.certUrls.urlShareLinkedinProfile}&organizationId=${EAN_LINKEDIN_ID}`,
                    certificationShareWhatsappUrl: certData.certUrls.urlShareSocialNetworkOnlyWhatsapp,
                    type: 2
                }
            });
        }).then(userCertification => {
            //certificationData = Object.assign({}, coursesCertificationData);
            setWaiting(false)
            setToastProperties({
                show: true,
                type: 'success',
                message: `<span>Tu Insignia para el curso <strong>{course.name}</strong> se generó exitosamente. Ya puedes descargarla.</span>`,
                position: { horizontal: 'left' }});
        }).catch(() => {
            setWaiting(false)
            setToastProperties({
                show: true,
                type: 'error',
                message: `<span>Ocurrió un error generando la Insignia para el curso <strong>{course.name}</strong>. Intenta nuevamente.</span>`,
                position: { horizontal: 'left' }});
        })
    }
    const companyName = companies.find(
        (item) => parseInt(item.id) === company,
      );
      const segmentName = segments.find(
        (item) => parseInt(item.type) === segmentId,
      );
    const diasPasados = calculatePastDays(logSession[0]?.createdAt);
    return (
        <>
            <Link to='/admin/usuarios' className='px-4 px-sm-5 text_primary fw-bold'>Volver a usuarios</Link>
            <Toast {...toastProperties} closeCallback={afterCloseToast} ></Toast>
            <Modal
                open={showModalUpdated}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className='B2B__modal-base ProfilePage__outline-none p-4'>
                    <form onSubmit={handleAssignPlan}>
                        <div className='row justify-content-center align-items-center'>
                            <div className='col-12'>
                                <div className='AcceptDialog__body'>Agregar plan al usuario </div>
                                <Select
                                    className="mb-2"
                                    value={selectedPlan}
                                    options={
                                        plans?.length !== 0 &&
                                        plans.sort((a, b) => a.title.localeCompare(b.title))
                                            .map((data) => ({
                                                value: data.id,
                                                label: data.title
                                            }))
                                    }
                                    onChange={handleSelectPlanChange}
                                />
                                <input name='approvalCode' placeholder='Comprobante de asignación de plan' className='form-control UserFormContainer__input' onChange={handleApprovalCodeChange} />
                            </div>
                            <div className='ProfilePage__modal-button-container d-flex justify-content-center'>
                                <div className='row w-100'>
                                    <div className="col-12 col-md-6"><button className='B2B__ActionButton ActionButton--primary' type="submit">Agregar plan</button></div>
                                    <div className="col-12 col-md-6"><button className='B2B__ActionButton ActionButton--secondary' onClick={handleCloseModal}>Cancelar</button></div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </Modal>

            <Modal
                open={showModalChangeB2B}
                onClose={handleCloseModalChangeB2B}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className='B2B__modal-base ProfilePage__outline-none p-4'>
                    <form onSubmit={handleChangeB2B}>
                        <div className='row justify-content-center align-items-center'>
                            <div className='col-12'>
                                <div className='AcceptDialog__body'>Cambiar de B2B al usuario</div>
                                <select name="courseId" className="form-select PlanFormContainer__input mb-3" defaultValue={selectedOption ? selectedOption : ''} onChange={handleSelectB2BChange}>
                                    <option value='' key=''>Selecciona un B2B</option>
                                    {
                                        companies.length !== 0 &&
                                        companies.map((data, index) => {
                                            return (
                                                <option value={data?.id} key={data?.id}>{data?.name}</option>
                                            );
                                        })
                                    }
                                </select>
                            </div>
                            <div className='ProfilePage__modal-button-container d-flex justify-content-center'>
                                <div className='row w-100'>
                                    <div className="col-12 col-md-6"><button className='B2B__ActionButton ActionButton--primary' type="submit">Cambiar de B2B</button></div>
                                    <div className="col-12 col-md-6"><button className='B2B__ActionButton ActionButton--secondary' onClick={handleCloseModalChangeB2B}>Cancelar</button></div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </Modal>
            <Modal
                open={showModalChangeSegment}
                onClose={handleCloseModalChangeSegment}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className='B2B__modal-base ProfilePage__outline-none p-4'>
                    <form onSubmit={handleChangeSegment}>
                        <div className='row justify-content-center align-items-center'>
                            <div className='col-12'>
                                <div className='AcceptDialog__body'>Cambiar de Segmentación al usuario</div>
                                <select name="segmentId" className="form-select PlanFormContainer__input mb-3" defaultValue={selectedSegment ? selectedSegment : ''} onChange={handleSelectSegmentChange}>
                                    <option value='' key=''>Selecciona una Segmentación</option>
                                    {
                                        segments.length !== 0 &&
                                        segments.map((data, index) => {
                                            return (
                                                <option value={data?.type} key={data?.id}>{data?.name}</option>
                                            );
                                        })
                                    }
                                </select>
                            </div>
                            <div className='ProfilePage__modal-button-container d-flex justify-content-center'>
                                <div className='row w-100'>
                                    <div className="col-12 col-md-6"><button className='B2B__ActionButton ActionButton--primary' type="submit">Cambiar de Segmentación</button></div>
                                    <div className="col-12 col-md-6"><button className='B2B__ActionButton ActionButton--secondary' onClick={handleCloseModalChangeSegment}>Cancelar</button></div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </Modal>
            <AcceptDialog {...dialogProperties}></AcceptDialog>

            <div className='text-center'><h1>Perfil de usuario </h1> </div>
            <div className='d-flex justify-content-center'>
                <div className='UserFormContainer row m-2 p-3 col-10 col-md-10'>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className='mb-3'>
                            <div className='row'>
                                <div className="col-12 col-md-6">
                                    <label className="form-label UserFormContainer__label">Nombre</label>
                                    <input
                                        name='name'
                                        disabled={!isAdmin}
                                        placeholder={CONSTANTS.LABEL_USERNAME_PLACEHOLDER}
                                        {...register("name",{required:CONSTANTS.MESSAGE_USERNAME_REQUIRED})}
                                        className={`form-control UserFormContainer__input ${errors.name?'UserFormContainer__input--error':''}`}>
                                    </input>
                                <ErrorMessage message={errors.mane?errors.mane.message:''}/>
                                </div>
                                <div className="col-12 col-md-6">
                                    <label className="form-label UserFormContainer__label">Apellido</label>
                                    <input
                                        name='lastname'
                                        disabled={!isAdmin}
                                        placeholder={CONSTANTS.LABEL_USERNAME_PLACEHOLDER}
                                        {...register("lastname",{required:CONSTANTS.MESSAGE_USERNAME_REQUIRED})}
                                        className={`form-control UserFormContainer__input ${errors.lastname?'UserFormContainer__input--error':''}`}>
                                    </input>
                                </div>
                            </div>
                        </div>
                        <div className='mb-3'>
                            <div className='row'>
                                <div className="col-12 col-md-6">
                                    <label className="form-label UserFormContainer__label">Correo electrónico</label>
                                    <input
                                        type='text'
                                        name='email'
                                        disabled={!editSensitiveData&&!isAdmin}
                                        className={`form-control auth__input 
                                        ${errors.email?"auth__input--error":""}`}
                                        placeholder={CONSTANTS.LABEL_EMAIL_PLACEHOLDER}
                                        {...register("email", { required: CONSTANTS.MESSAGE_EMAIL_REQUIRED,
                                            pattern: {
                                                value:  CONSTANTS.PATTERN_EMAIL ,
                                                message:  CONSTANTS.MESSAGE_EMAIL_INVALID
                                            }})}
                                    />
                                    <ErrorMessage message={errors.email?errors.email.message:''}/>
                                </div>
                                <div className="col-12 col-md-6">
                                    <label className="form-label UserFormContainer__label">Fecha de registro</label>
                                    <label className="form-label UserFormContainer__sublabel">{moment.utc(users?.createdAt).format('DD/MM/YYYY')}</label>
                                </div>
                            </div>
                        </div>
                        <div className='mb-3'>
                            <div className='row'>
                                <div className="col-12 col-md-6">
                                    <label className="form-label UserFormContainer__label">Tipo de plan adquirido</label>
                                    <label className="form-label UserFormContainer__sublabel">
                                        { users['plans.title'] != null
                                            ? users['plans.title']
                                            : <>No tiene plan asignado&nbsp;&nbsp;  
                                            <Link to={{}} className="FilterAdmin__option-button" onClick={handleOpenModal}>
                                                <span>Asignar plan</span>
                                            </Link></> }</label>
                                </div>
                                <div className="col-12 col-md-6">
                                    <label className="form-label UserFormContainer__label">Último inicio de sesión</label>
                                    <label className="form-label UserFormContainer__sublabel">{users.lastLogin ? moment.utc(users?.lastLogin).format('DD/MM/YYYY') : 'No ha iniciado sesión por primera vez'}</label>
                                </div>
                            </div>
                        </div>

                        <div className='mb-3'>
                            <div className='row'>
                                <div className="col-12 col-md-6">
                                    <label className="form-label UserFormContainer__label">Tipo de documento</label>
                                    <select
                                        defaultValue=""
                                        className={`form-select UserAdmin__select 
                                        ${errors.documentType?"UserAdmin__input--error":""}`}
                                        aria-label="Default select example"
                                        name='documentType'
                                        disabled={!editSensitiveData}
                                        {...register("documentType", {onChange:handleSelectChange })}
                                    >
                                        <option value="CC">Cédula de Ciudadanía</option>
                                        <option value="CE">Cédula de Extranjería</option>
                                        <option value="DIE">Documento de Identidad Extranjera</option>
                                        <option value="PA">Pasaporte</option>
                                        <option value="TI">Tarjeta de Identidad</option>
                                        <option value="PEP">Permiso Especial de Permanencia</option>
                                        <option value="DNI">Documento nacional de identidad</option>
                                        <option value="IPJ">Identificador de persona jurídica</option>
                                        <option value="CERC">Certificado Cabildo</option>
                                        <option value="NIP">Número Identificación Personal</option>
                                        <option value="NSE">Número por Secretaría de Educación</option>
                                        <option value="NUIP">Número Único Identificación Personal</option>
                                        <option value="REGC">Registro Civil</option>
                                        <option value="SNP">Registro SNP</option>
                                        <option value="TP">Tarjeta profesional</option>
                                        <option value="Visa">Visa</option>
                                        <option value="NIT">N.I.T.</option>
                                        <option value="PPT">Permiso por Protección Temporal</option>
                                    </select>
                                </div>
                                <div className="col-12 col-md-6">
                                    <label className="form-label UserFormContainer__label">Número de documento</label>
                                    { isAdmin && <button className="UserFormContainer__edit-button" onClick={handleEditSensitiveData} type='button' disabled={editSensitiveData}>
                                        <i className='fa-solid fa-pen'></i>
                                    </button> }
                                    <input
                                        type='input'
                                        name='document'
                                        className={`form-control auth__input 
                                        ${errors.userDocument?"auth__input--error":""}`}
                                        placeholder={LABEL_DOCUMENT_PLACEHOLDER}
                                        disabled={!editSensitiveData}
                                        {...register("document", {
                                            pattern: {
                                                value: docPatternMessage.pattern,
                                                message: docPatternMessage.message
                                            }, required:CONSTANTS.MESSAGE_DOCUMENT_REQUIRED })}
                                    />
                                    <ErrorMessage message={errors.userDocument?errors.userDocument.message:''}/>
                                </div>
                            </div>
                        </div>
                        { editSensitiveData!==0 && <div className='mb-3'>
                            <div className='row'>
                                <div className="col-12 col-md-6">
                                    <label className="form-label UserFormContainer__label">Cambiar contraseña</label>
                                    <input
                                        name='password'
                                        type='password'
                                        disabled={!editSensitiveData}
                                        placeholder={CONSTANTS.MESSAGE_PASSWORD_BACKOFFICE}
                                        {...register("password")}
                                        className={`form-control UserFormContainer__input ${errors.password?'UserFormContainer__input--error':''}`}>
                                    </input>
                                </div>
                                <div className="col-12 col-md-6"> </div>
                            </div>
                        </div> }
                        <div className='mb-3'>
                            <div className='row'>
                                <div className="col-12 col-md-6">
                                    <label className="form-label UserFormContainer__label">Teléfono</label>
                                    <input
                                        name='phone'
                                        disabled={!isAdmin}
                                        placeholder={CONSTANTS.LABEL_TELEPHONE_PLACEHOLDER}
                                        {...register("phone",{required:CONSTANTS.MESSAGE_TELEPHONE_REQUIRED})}
                                        className={`form-control UserFormContainer__input ${errors.phone?'UserFormContainer__input--error':''}`}>
                                    </input>
                                    <ErrorMessage message={errors.phone?errors.phone.message:''}/>
                                </div>
                                <div className="col-12 col-md-6">
                                    <label className="form-label UserFormContainer__label">Dirección</label>
                                    <input
                                        placeholder={CONSTANTS.LABEL_ADDRESS_PLACEHOLDER}
                                        name='address'
                                        disabled={!isAdmin}
                                        {...register("address",{required:CONSTANTS.MESSAGE_ADDRESS_REQUIRED})}
                                        className={`form-control UserFormContainer__input ${errors.address?'UserFormContainer__input--error':''}`}>
                                    </input>
                                    <ErrorMessage message={errors.address?errors.address.message:''}/>
                                </div>
                            </div>
                        </div>
                        <div className='mb-3'>
                            <div className='row'>
                                <div className="col-12 col-md-6">
                                    <label className="form-label UserFormContainer__label">Tipo de usuario</label>
                                    {!editSensitiveData && parseInt(users.companyId)===1 ? <label className="form-label UserFormContainer__sublabel">{users?.['usertype.description']}</label> :
                                    <select
                                        defaultValue=""
                                        className={`form-select UserAdmin__select 
                                        ${errors.type?"UserAdmin__input--error":""}`}
                                        aria-label="Default select example"
                                        name='type'
                                        disabled={!editSensitiveData}
                                        {...register("type", {onChange:handleSelectChange })}
                                    >
                                        <option value="1">Público General</option>
                                        <option value="2">Estudiantes Eanistas</option>
                                        <option value="3">Docente de Planta</option>
                                        <option value="4">Docente de Cátedra</option>
                                        <option value="5">Colaborador</option>
                                        <option value="6">Egresado</option>
                                    </select> }
                                </div>
                                <div className="col-12 col-md-6">
                                    <label className="form-label UserFormContainer__label">B2B al que pertenece</label>
                                    <label className="form-label UserFormContainer__sublabel">{companyName ? companyName?.name : 'ERROR'} &nbsp;&nbsp;{ isAdmin && <Link to={{}} className="FilterAdmin__option-button" onClick={handleOpenModalChangeB2B}>
                                            <span>Cambiar de B2B</span>
                                        </Link> }</label>
                                </div>
                            </div>
                        </div>
                        <div className='mb-3'>
                            <div className='row'>
                                <div className="col-12 col-md-6">
                                    <label className="form-label UserFormContainer__label">Estado del usuario</label>
                                    <label className="form-label UserFormContainer__sublabel">{users?.active===1 ? 'Activo' : 'Inactivo'} &nbsp;&nbsp;{ sessionUser.type === 0 || sessionUser.type === 1 && <Link to={{}} className="FilterAdmin__option-button" onClick={handleChangeState}>
                                            <span>Cambiar Estado</span>
                                        </Link> }</label>
                                </div>                                
                                <div className="col-12 col-md-6">
                                    <label className="form-label UserFormContainer__label">Segmentación del usuario</label>
                                    <label className="form-label UserFormContainer__sublabel">{segmentName && parseInt(company)!==1 && segmentName?.name} &nbsp;&nbsp;{ isAdmin && <Link to={{}} className="FilterAdmin__option-button" onClick={handleOpenModalChangeSegment}>
                                            <span>Cambiar de Segmentación</span>
                                        </Link> }</label>
                                </div>
                            </div>
                        </div>
                        <div className='mb-3'>
                            <div className='row'>
                                <div className="col-12 col-md-6">
                                    <label className="form-label UserFormContainer__label">Cantidad de inicios de sesión</label>
                                    <label className="form-label UserFormContainer__sublabel">{totalSession} veces</label>
                                 </div>
                                 <div className="col-12 col-md-6">
                                    <label className="form-label UserFormContainer__label">Cantidad de inicios de sesión</label>
                                    <label className="form-label UserFormContainer__sublabel">{diasPasados !== null && (
        <p>
          Han pasado {diasPasados} {diasPasados === 1 ? 'día' : 'días'} desde la fecha seleccionada.
        </p>
      )}</label>
                                </div>
                            </div>
                        </div>
                        { users?.verifyShortToken &&
                             <div className='mb-3'>
                             <div className='row'>
                                 <div className="col-12 col-md-6">
                                     <label className="form-label UserFormContainer__label">Código de confirmación</label>
                                     <label className="form-label UserFormContainer__sublabel">{users?.verifyShortToken}</label>
                                 </div>
                                 <div className="col-12 col-md-6"></div>
                             </div>
                         </div>
                        }
                        <div className='' style={{width: "100%", marginTop: "17px", overflowX: "auto"}}>
                        <div className='mb-3'>
                            <label className="form-label PlanFormContainer__label">Cursos inscritos</label>
                            <table className='UserAdmin__table' >
                                <thead className='UserAdmin__subtable-header' style={{height: "52px"}}>
                                <tr style={{cursor: "pointer"}}>
                                    {headercourses.map((header, index)=>{
                                        return(
                                            <th key={index} style={{width: header.width, paddingLeft: "16px", textAlign: "center"}}>{header.name}</th>
                                        )
                                    })}
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    courses.length !== 0 ?
                                        courses.map((data, index)=>{
                                            return(
                                                <tr className={`UserAdmin__table-row ${index%2 ===0 ? "UserAdmin__table-pair-row": "UserAdmin__table-odd-row" }`} key={index}>
                                                    <td style={{paddingLeft: "16px", textAlign: "center"}}>{moment.utc(data?.['createdAt']).format('DD/MM/YYYY')}</td>
                                                    <td style={{paddingLeft: "16px", textAlign: "center"}}>{data?.['course.title']}</td>
                                                    <td style={{paddingLeft: "16px", textAlign: "center"}}>{data?.progress}%</td>
                                                    <td style={{paddingLeft: "16px", textAlign: "-webkit-center"}}>{data?.status === "completed" && data?.avgScore > 70 ?
                                                            <div className={`w-75 mx-0 px-0 ProfilePage__download-certificate-button`}
                                                                onClick={(event) => data?.['attr.type'] == 1 && generateCertificate(data, index, event)}>
                                                                <span>{waiting ? 'Generando ' : 'Generar '}{data?.['attr.type'] == 1 ? 'Certificado' : 'Insignia'}</span>
                                                                {waiting && <>&nbsp;<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></>}
                                                            </div>
                                                         :'NO DISPONIBLE'}</td>
                                                </tr>
                                            );
                                        })
                                        :   <tr>
                                            <td colSpan="7" style={{padding: "16px", textAlign: "center"}}><strong>No existen registros</strong></td>
                                        </tr>
                                }
                                </tbody>
                            </table>
                        </div>
                        </div>
                        <div className='' style={{width: "100%", marginTop: "17px", overflowX: "auto"}}>
                        <div className='mb-3'>
                            <label className="form-label PlanFormContainer__label">Historial de pagos</label>
                                <table className='UserAdmin__table' >
                                    <thead className='UserAdmin__subtable-header' style={{height: "52px"}}>
                                    <tr style={{cursor: "pointer"}}>
                                        {headers.map((header, index)=>{
                                            return(
                                                <th key={index} style={{width: header.width, paddingLeft: "16px", textAlign: "center"}}>{header.name}</th>
                                            )
                                        })}
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        history.length !== 0 ?
                                        history.map((data, index)=>{
                                            return(
                                                <tr className={`UserAdmin__table-row ${index%2 ===0 ? "UserAdmin__table-pair-row": "UserAdmin__table-odd-row" }`} key={index}>
                                                    <td style={{paddingLeft: "16px", textAlign: "center"}}>{moment.utc(data?.createdAt).format('DD/MM/YYYY')}</td>
                                                    <td style={{paddingLeft: "16px", textAlign: "center"}}>{data.approvalCode}</td>
                                                    <td style={{paddingLeft: "16px", textAlign: "center"}}>{moment.utc(data?.startsAt).format('DD/MM/YYYY')}</td>
                                                    <td style={{paddingLeft: "16px", textAlign: "center"}}>{moment.utc(data?.endsAt).format('DD/MM/YYYY')}</td>
                                                    <td style={{paddingLeft: "16px", textAlign: "center"}}>{data['plan.title']}</td>
                                                    <td style={{paddingLeft: "16px", textAlign: "center"}}>{data.paidPrice} COP</td>
                                                    <td style={{paddingLeft: "16px", textAlign: "center"}}>Completado</td>
                                                    <td style={{paddingLeft: "16px", textAlign: "center"}}>{(data.paidPrice===0&&getPlanStatus(data?.startsAt,data?.endsAt)<3)&&isAdmin ? <Link to={('#')} className="FilterAdmin__option-button" onClick={(event) => handleDeleteFreePlan(data.id, event)}><i className='fa-solid fa-trash UserAdmin__color-red'></i></Link> : ''}</td>
                                                </tr>
                                            );
                                        })
                                        :   <tr>
                                                <td colSpan="8" style={{padding: "16px", textAlign: "center"}}><strong>No existen registros</strong></td>
                                            </tr>
                                    }
                                    </tbody>
                                </table>
                        </div>
                        </div>
                        <div className='' style={{width: "100%", marginTop: "17px", overflowX: "auto"}}>
                        <div className='mb-3'>
                            <label className="form-label PlanFormContainer__label">Cursos individuales adquiridos</label>
                                <table className='UserAdmin__table' >
                                    <thead className='UserAdmin__subtable-header' style={{height: "52px"}}>
                                    <tr style={{cursor: "pointer"}}>
                                        {headerpurchasecourses.map((header, index)=>{
                                            return(
                                                <th key={index} style={{width: header.width, paddingLeft: "16px", textAlign: "center"}}>{header.name}</th>
                                            )
                                        })}
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        historyCourses.length !== 0 ?
                                        historyCourses.map((data, index)=>{
                                            return(
                                                <tr className={`UserAdmin__table-row ${index%2 ===0 ? "UserAdmin__table-pair-row": "UserAdmin__table-odd-row" }`} key={index}>
                                                    <td style={{paddingLeft: "16px", textAlign: "center"}}>{moment.utc(data?.createdAt).format('DD/MM/YYYY')}</td>
                                                    <td style={{paddingLeft: "16px", textAlign: "center"}}>{data.approvalCode}</td>
                                                    <td style={{paddingLeft: "16px", textAlign: "center"}}>{data['course.title']}</td>
                                                    <td style={{paddingLeft: "16px", textAlign: "center"}}>{data.paidPrice} COP</td>
                                                </tr>
                                            );
                                        })
                                        :   <tr>
                                                <td colSpan="4" style={{padding: "16px", textAlign: "center"}}><strong>No existen registros</strong></td>
                                            </tr>
                                    }
                                    </tbody>
                                </table>
                        </div>
                        </div>
                        { isAdmin && <SubmitFormButton
                            main_title="Guardar"
                            wait_title="Guardando..."
                            loading={fetchData.loading}>
                        </SubmitFormButton> }
                    </form>
                    <ErrorMessage message={fetchData.error?((!!fetchData.error.code)?CONSTANTS.MESSAGE_PLAN_ERROR:CONSTANTS.MESSAGE_GENERIC_ERROR):''}/>
                </div>
            </div>
            <Toast title="Error" {...toastProperties} closeCallback={afterCloseErrorToast} position={{vertical:'bottom', horizontal: 'left'}} ></Toast>
        </>
    );
}